import './index.css';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
  block?: boolean
  size?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
                                                                    children,
                                                                    className,
                                                                    block,
                                                                    size,
                                                                    type,
                                                                    ...rest
                                                                  }: ButtonProps, ref) => {
  const classes = ['btn'];
  const btnType = type && type.includes('submit') ? 'submit' : 'button';

  if (className) classes.push(className);
  if (block) classes.push('btn-block');
  if (size) classes.push(`btn-${size}`);
  if (type) type.split(' ').map(typ => classes.push(`btn-${typ}`));

  return (
    <button ref={ref} type={btnType} className={classes.join(' ')} {...rest}>
      <span>{children}</span>
    </button>
  );
});

Button.displayName = 'Button'

export default Button
