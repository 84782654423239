"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactRedux = require("react-redux");
var _actions = _interopRequireDefault(require("./actions"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onNavOpen: function onNavOpen() {
      dispatch(_actions["default"].openMainNav());
    },
    onNavClose: function onNavClose() {
      dispatch(_actions["default"].closeMainNav());
    }
  };
};
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    isNavOpen: state.mainNav.isNavOpen
  };
};
var _default = exports["default"] = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps);