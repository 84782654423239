import apiConfig                       from 'res-api-cli/build/config';
import { getLocalStorageSettingValue } from './settings';
import { History }                     from 'history';
import reportError                     from 'lib/errorReporting';

export const getServerURL = (): string => {

  const apiOverride: string | boolean = getLocalStorageSettingValue('api-url');

  if (typeof apiOverride === 'string' && apiOverride) {
    return apiOverride;
  }

  switch (window._env_?.REACT_APP_AWS_ENV) {
    case 'academy':
      return 'https://api.forseti-academy.org';
    case 'lumin-sandbox':
      return 'https://api.lumin-sandbox.co.uk';
    case 'staging':
      return 'https://api.os-test.me';
    case 'sandbox':
      return 'https://sandbox-api.os-test.me';
    case 'production':
      return 'https://case-api.ombudsman-services.org';
    case 'dr':
      return 'https://cms-api.lumin-dr.co.uk';
    default:
      return 'http://localhost:2999';
  }
}

export const configureApi = ({ history }: { history: History }) => {
  apiConfig.apiUrl = getServerURL();

  // @ts-expect-error apiConfig has not been set up well in old world code
  apiConfig.gtmId = ((env) => {
    switch (env) {
      case 'staging':
        return 'GTM-N9DQHLH';
      case 'sandbox':
        return undefined;
      case 'academy':
        return 'GTM-TTNMQ3R';
      case 'production':
        return 'GTM-NVN24M7';
      default:
        return undefined;
    }
  })(window._env_?.REACT_APP_AWS_ENV);

  // @ts-expect-error apiConfig has not been set up well in old world code
  apiConfig.onError = (e, context) => {
    switch (e.name) {
      case 'UnauthorizedException':
        history.push('/unauthorized');
        break;
      case 'ForbiddenException':
        history.push('/forbidden');
        break;
      case 'NotFoundException':
        history.push('/forbidden');
        break;
      case 'ValidationException':
        break;
      case 'BunnyPublisherTimeoutException':
        break;
      case 'AuthTokenExpiredException':
        history.push('/signout');
        history.push('/signin');
        break;
      default:
        reportError(e, `${e?.name} - Unhandled API exception`, { context });
        history.push('/unavailable');
        break;
    }

    return Promise.reject(e);
  }
}

export default configureApi;
