"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectToQueryString = exports["default"] = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
var _isArray = _interopRequireDefault(require("lodash/isArray"));
var _moment = _interopRequireDefault(require("moment"));
var _keys = _interopRequireDefault(require("lodash/keys"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var esc = encodeURIComponent;
var arrayToQueryString = function arrayToQueryString(key, array, brackets) {
  var suffix = brackets ? '[]=' : '=';
  return array.map(function (value) {
    return esc(key) + suffix + esc(value);
  }).join('&');
};
var keyValueToQueryString = function keyValueToQueryString(key, value, brackets) {
  if ((0, _isArray["default"])(value)) return arrayToQueryString(key, value, brackets);else if (_moment["default"].isMoment(value)) return esc(key) + '=' + esc(value.format('YYYY-MM-DD'));else return esc(key) + '=' + esc(value);
};
var objectToQueryString = exports.objectToQueryString = function objectToQueryString(object) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$brackets = _ref.brackets,
    brackets = _ref$brackets === void 0 ? true : _ref$brackets;
  return (0, _keys["default"])(object).map(function (key) {
    return object[key] && object[key] !== 'undefined' && keyValueToQueryString(key, object[key], brackets);
  }).filter(function (x) {
    return x;
  }).join('&');
};
var _default = exports["default"] = objectToQueryString;