"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Nav = _interopRequireDefault(require("./Nav.component"));
var _container = _interopRequireDefault(require("../container"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
//
// used http://callmenick.com/post/slide-and-push-menus-with-css3-transitions
// to build push menu check for more details
//
var _default = exports["default"] = (0, _container["default"])(_Nav["default"]);