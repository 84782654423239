import React, { ReactNode }                          from 'react';
import Modal, { Props as ModalProps }                from 'react-modal';
import classNames                                    from 'classnames'
import './index.css';

const customStyles = {
  overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
  content: { outline: 'none' }
};

Modal.setAppElement(process.env.NODE_ENV === 'test' ? 'body' : '#root');

export default function Popup({ contentLabel, children, className = '', ...restProps }: PopupProps) {
  return (
    <Modal
      className={classNames('popup-window', className)}
      contentLabel={contentLabel}
      style={customStyles}
      {...restProps}
    >
      {children}
    </Modal>
  );
}

interface PopupProps extends ModalProps {
  contentLabel: string
  className?: string
  children: ReactNode
}
