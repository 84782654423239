import {validatorAsync} from "res-api-cli/build/validator";
import apiConfig from 'res-api-cli/build/config';
import {getLocalStorageSettingValue} from "settings";
import reportError, {ErrorTags} from "lib/errorReporting";

const schema = {
  featureFlagName: {title: "Feature Flag Name", required: true}
};

export default (params = {}, opts = {}) =>
  validatorAsync(schema, params).then(validParams => {
    return new Promise((resolve, reject) => {

      let featureUrl = apiConfig.apiUrl;

      const urlOverride = getLocalStorageSettingValue("feature-url");
      if (urlOverride) {
        featureUrl = urlOverride;
      }

      const finalUrl = `${featureUrl}/cms/feature/flag/${validParams.featureFlagName}`;

      fetch(finalUrl, {
        method: 'GET',
        headers: apiConfig.headers
      }).then(response => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.json());
        } else {
          reportError(new Error(response.statusText), 'AppConfig Error - Invalid response status', {
            context: {
              tags: `${ErrorTags.APP_CONFIG}`,
              requestUrl: finalUrl,
              requestMethod: 'GET',
              responseStatus: response.status ?? 0,
              responseStatusText: response.statusText ?? '',
              featureFlagName: validParams.featureFlagName ?? '',
              error: `Failed to fetch feature flag ${validParams.featureFlagName}`
            }
          });
          reject(response);
        }
      }).catch(err => {
        reportError(err, 'AppConfig Error - Exception', {
          context: {
            tags: `${ErrorTags.APP_CONFIG}`,
            requestUrl: finalUrl,
            requestMethod: 'GET',
            featureFlagName: validParams.featureFlagName ?? '',
            error: `Failed to fetch feature flag ${validParams.featureFlagName}`
          }
        });
        reject(err);
      });
    })
  });
