"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dropValidationExceptionNamespace = exports.dropNamespace = void 0;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.function.name.js");
var _removeNamespace = _interopRequireDefault(require("res-extensions/build/removeNamespace"));
var _validation = _interopRequireDefault(require("./exceptions/validation"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var dropNamespace = exports.dropNamespace = function dropNamespace() {
  for (var _len = arguments.length, namespaces = new Array(_len), _key = 0; _key < _len; _key++) {
    namespaces[_key] = arguments[_key];
  }
  return function (data) {
    var res = namespaces.reduce(function (res, value) {
      if (data[value]) res[value] = data[value];
      return res;
    }, {});
    if (Object.keys(res).length == 1) {
      return res[Object.keys(res)[0]];
    } else {
      return res;
    }
  };
};
var dropValidationExceptionNamespace = exports.dropValidationExceptionNamespace = function dropValidationExceptionNamespace(namespace) {
  return function (e) {
    return Promise.reject(namespace && e.name === 'ValidationException' ? new _validation["default"]((0, _removeNamespace["default"])(e.errors, namespace)) : e);
  };
};