import Honeybadger from 'honeybadger-js';
import AuthData    from './authData';

export const ErrorTags = {
  // CASE
  COMPLAINT: 'complaint',
  COMPLAINT_SUMMARY: 'complaintSummary',
  DECISION: 'decision',
  COMMENT: 'comment',
  ADDITIONAL_SUPPORT: 'additionalSupport',
  SUBMISSION_FLOW: 'submissionFlow',
  // FEATURE FLAGS
  APP_CONFIG: 'appConfig',
  // UI
  UI: 'ui',
  UI_SCROLLING: 'scrolling',
  UI_ANIMATION: 'animation',
  // USERS
  SUPPLIER: 'supplier',
  // API
  API: 'api',
  API_FETCH: 'fetch',
  API_AXIOS: 'axios',
}

const reportError = (err: Error, message = '', opts = {}) => {
  const env = window._env_?.REACT_APP_AWS_ENV || 'development';
  if (env === 'development') {
    console.error(err);
    return;
  }

  let authData;
  try {
    authData = AuthData.get();
  } catch (e) {
    // window or localstorage undefined
  }
  // @ts-expect-error as the context can contain anything
  let context = opts?.context || {};
  if (authData) {
    context = {
      ...context,
      user_role: authData?.user?.role,
      user_id: authData?.user?.id ?? 0
    };
  } else {
    context = {
      ...context,
      user_role: 'no-auth',
    }
  }
  opts = {
    ...opts,
    context
  }
  Honeybadger.notify(err, message, opts);
}

export default reportError;
