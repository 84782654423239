import HoneyBadger from 'honeybadger-js';

export default () => {
  const env = window._env_?.REACT_APP_AWS_ENV || 'development';

  if (['staging', 'sandbox', 'academy', 'production'].includes(env)) {
    HoneyBadger.configure({
      apiKey: 'bb801bad',
      environment: env,
      // @ts-expect-error Notice isn't exported by the Honeybadger library
      beforeNotify: (notice) => {
        notice.fingerprint = [
          notice.errorClass, // TypeScript ensures this is correctly typed
          notice.component,
          notice.backtrace?.join(',') || '' // Use optional chaining and fallback for undefined backtrace
        ].join(':');
      }
    });
  }
};
