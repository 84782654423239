import Button from "../common/Button";
import Popup from "components/common/Popup";
import React, {useState} from "react";
import { featureIsEnabled } from "settings";

export const LOCALSTORAGE_KEY = 'maintenance-notice-05-03-2024';

export default ({currentUser}) => {
  const [showPopup, setShowPopup] = useState(true);

  const onClose = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'read');
    setShowPopup(false)
  }

  if (!featureIsEnabled('maintenance-popup-message') || currentUser === undefined || localStorage.getItem(LOCALSTORAGE_KEY)) {
    return null
  }

  return (
    <Popup contentLabel="Maintenance notice popup" isOpen={showPopup}>
      <div className="popup-content">
        <h3>Please note</h3>
        <p>On the 5th March 2024 the system will be unavailable between 6am and 7am GMT for scheduled maintenance.</p>
        <p>After this time, the system will be available as normal and will operate in the same way as before.</p>
        <p>Thanks for your cooperation.</p>
      </div>
      <div data-flex="m-center column md-row md-row-reverse nowrap">
        <Button type="transparent" onClick={onClose}>
          Close
        </Button>
      </div>
    </Popup>
  )
}
