import Button from "../common/Button";
import Popup from "components/common/Popup";
import React, {useState} from "react";
import { featureIsEnabled } from "settings";

export const LOCALSTORAGE_KEY = 'rebrand-notice';

export default ({currentUser}) => {
  const [showPopup, setShowPopup] = useState(true);

  const onClose = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'read');
    setShowPopup(false)
  }

  if (!featureIsEnabled('rebrand-popup-message') || currentUser === undefined || localStorage.getItem(LOCALSTORAGE_KEY)) {
    return null
  }

  return (
    <Popup contentLabel="Rebrand notice popup" isOpen={showPopup}>
      <div className="popup-content">
        <h3>Please note</h3>
        <p>You’re going to notice some changes to the look of your online account over the coming weeks. We’re letting you know about these now so the updates don’t come as a surprise on the day.</p>
        <p>You’ll also notice that communications from us will be from Energy Ombudsman or Communications Ombudsman rather than Ombudsman Services. Please be assured that this won’t affect our existing day-to-day services.</p>
        <p>There’s no need to contact us about these changes.</p>
      </div>

      <div data-flex="m-center column md-row md-row-reverse nowrap">
        <Button type="transparent" onClick={onClose}>
          Close
        </Button>
      </div>
    </Popup>
  )
}
